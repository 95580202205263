import React from "react"
import { graphql } from "gatsby"

// Utility Functions
import { handleMenuItems } from "../utils/handleMenuItems"

// Custom components
import { Nav } from "../components/Nav"
import { Header } from "../components/Header"
import { Footer } from "../components/Footer"

// Contact page template
export default function TemplateContact(props) {
  const {
    data: {
      wordpressPage: {
        slug,
        acf: {
          contact_headline: contactHeadline,
          contact_hero_image_left: contactHeroImageLeft,
          contact_hero_image_right: contactHeroImageRight,
          contact_address_title: contactAddressTitle,
          contact_address_line_one: contactAddressLineOne,
          contact_address_line_two: contactAddressLineTwo,
          contact_phone_title: contactPhoneTitle,
          contact_phone_number: contactPhoneNumber,
          contact_email_title: contactEmailTitle,
          contact_email_address: contactEmailAddress,
        },
      },
      wordpressAcfOptions: {
        options: {
          sitewide_company_information_company_name: companyName,
          sitewide_company_information_company_twitter: companyTwitter,
          sitewide_company_information_company_linkedin: companyLinkedIn,
        },
      },
      allWordpressPage: { edges },
    },
  } = props

  return (
    <section className="white contact">
      <Nav slug={slug} menuItemsSorted={handleMenuItems({ items: edges })} />

      <Header headerHeadline={contactHeadline} />

      <div className="diagonal">
        <div className="diagonal__wrapper">
          <div className="diagonal__container diagonal__container--left">
            <img className="diagonal__image--left" src={contactHeroImageLeft.localFile.childImageSharp.fluid.src} alt="Design by Robert Idol" />
          </div>
          <div className="diagonal__container diagonal__container--right">
            <div className="diagonal__banner">
              <div className="diagonal__row">
                <h3 className="diagonal__title">{contactAddressTitle}</h3>
                <h4 className="diagonal__subtitle">{contactAddressLineOne}</h4>
                <h4 className="diagonal__subtitle">{contactAddressLineTwo}</h4>
              </div>
              <div className="diagonal__row">
                <h3 className="diagonal__title">{contactPhoneTitle}</h3>
                <a className="diagonal__link" href={`tel:+1${contactPhoneNumber.split("-").join("")}`}>
                  {contactPhoneNumber.split("-").join(".")}
                </a>
              </div>
              <div className="diagonal__row">
                <h3 className="diagonal__title">{contactEmailTitle}</h3>
                <a className="diagonal__link" href={`mailto:${contactEmailAddress}`}>
                  {contactEmailAddress}
                </a>
              </div>
            </div>
            <img className="diagonal__image--right" src={contactHeroImageRight.localFile.childImageSharp.fluid.src} alt="Contact Robert Idol" />
          </div>
        </div>
      </div>

      <Footer
        companyName={companyName}
        companyTwitter={companyTwitter}
        companyLinkedIn={companyLinkedIn}
        slug={slug}
        menuItemsSorted={handleMenuItems({ items: edges })}
      />
    </section>
  )
}

export const pageQuery = graphql`
  query {
    wordpressPage(title: { eq: "Contact" }) {
      slug
      acf {
        contact_headline
        contact_hero_image_left {
          localFile {
            childImageSharp {
              fluid {
                src
              }
            }
          }
        }
        contact_hero_image_right {
          localFile {
            childImageSharp {
              fluid {
                src
              }
            }
          }
        }
        contact_address_title
        contact_address_line_one
        contact_address_line_two
        contact_phone_title
        contact_phone_number
        contact_email_title
        contact_email_address
      }
    }
    wordpressAcfOptions {
      options {
        sitewide_company_information_company_name
        sitewide_company_information_company_twitter
        sitewide_company_information_company_linkedin
      }
    }
    allWordpressPage(filter: { wordpress_parent: { eq: 0 } }) {
      edges {
        node {
          title
          menu_order
          slug
        }
      }
    }
  }
`
